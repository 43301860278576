import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/markdown-page.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Links`}</h1>
    <p>{`Links to some things about me...`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://chege.netlify.app"
        }}>{`Home`}</a>{` - This website :)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://chege.netlify.app/links"
        }}>{`Links`}</a>{` - This page`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://chege.netlify.app/blog"
        }}>{`Blog`}</a>{` - My various musings about JavaScript
and software development`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://twitter.com/chegehimself/"
        }}>{`Twitter`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/james-chege"
        }}>{`GitHub`}</a>{` - Bunch of projects on here :)`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      